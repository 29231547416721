const printDateValue = (date) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${date.year}`
}

export const patientEnrollmentTicketBody = (request) => {
  const {
    firstName,
    lastName,
    birthday,
    mobileNumber,
    personalEmail,
    address,
    employeeNumber,
    startDate,
    department,
    site,
    accessCode,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactEmail,
    emergencyContactRelationship,
  } = request;

  return `-Personal Details-
First Name: ${firstName || "N/A"}
Last Name: ${lastName || "N/A"}
Birthdate: ${birthday?.month?.value || "N/A"} ${birthday?.date?.value || ""} ${
    birthday?.year?.value || ""
  }
Mobile Number: ${mobileNumber || "N/A"}
Personal Email: ${personalEmail || "N/A"}

-Home Address-
Street Address: ${address?.streetAddress || "N/A"}
Barangay: ${address?.barangay?.label || address?.barangay || "N/A"}
City: ${address?.city || address?.city?.label || "N/A"}
Province: ${address?.province || address?.province?.label || "N/A"}

-Employee Information-
Work Email: ${personalEmail || "N/A"}
Start Date: ${startDate || "N/A"}
Employee Number: ${employeeNumber || "N/A"}
Site: ${site || "N/A"}
Department: ${department || "N/A"}
Access Code: ${accessCode || "N/A"}

-Emergency Contact-
Name: ${emergencyContactName || "N/A"}
Mobile Number: ${emergencyContactNumber || "N/A"}
Email: ${emergencyContactEmail || "N/A"}
Relationship to Emergency Contact: ${
    emergencyContactRelationship?.label || emergencyContactRelationship || "N/A"
  }`;
};
